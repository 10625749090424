import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';


function Page404() {

    const { t } = useTranslation()

    return (
        <Box
            width='100%'
            height='100%'
            display='flex'
            justifyContent="center"
            alignItems="center"
        >
            <Typography variant='h1'>
                {t('other.404')}
            </Typography>
        </Box>
    )
}

export default Page404