import React, { useContext } from 'react'
import { Box, IconButton, useTheme } from '@mui/material';
import { ColorModeContext } from '../../theme';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';


function ThemeToggler() {

    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);

    return (
        <Box display='flex'>
            <IconButton onClick={colorMode.toggleColorMode}>
                {theme.palette.mode === 'dark' ? (
                    <DarkModeOutlinedIcon sx={{ width: { xs: 40, md: 24 }, height: { xs: 40, md: 24 } }} />
                ) : (
                    <LightModeOutlinedIcon sx={{ width: { xs: 40, md: 24 }, height: { xs: 40, md: 24 } }} />
                )}
            </IconButton>
        </Box>
    )
}

export default ThemeToggler