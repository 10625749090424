import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  message: "",
  type: "success", // initial state can't be null
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    notifySuccess: (state) => {
      state.isOpen = true;
      state.message = "Submitted Successfully";
      state.type = "success";
    },
    close: (state) => {
      state.isOpen = false;
      state.message = "";
      state.type = "success";
    },
  },
});

export const getSnackbarState = (state) => state.snackbar.isOpen;
export const getSnackbarMessage = (state) => state.snackbar.message;
export const getSnackbarType = (state) => state.snackbar.type;

export const { notifySuccess, close } = snackbarSlice.actions;
export default snackbarSlice.reducer;
