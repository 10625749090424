/* Slice NOT USED - problems with storing MUI object in state (e.currenttarget) */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  anchor: null,
};

export const actionsMenuSlice = createSlice({
  name: "actionsMenu",
  initialState,
  reducers: {
    anchorMenu: (state, action) => {
      state.anchor = action.payload;
    },
    detachMenu: (state) => {
      state.anchor = null;
    },
  },
});

export const getActionsMenuAnchorStatus = (state) => state.actionsMenu.anchor;

export const { anchorMenu, detachMenu } = actionsMenuSlice.actions;
export default actionsMenuSlice.reducer;
