import { IconButton } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

function PasswordVisibility(props) {

    const { showPassword, onClick } = props

    return (
        <IconButton onClick={onClick} sx={{ padding: '5px' }}>
            {showPassword ? (
                <VisibilityOffOutlinedIcon sx={{ width: 20, height: 20 }} />
            ) : (
                <VisibilityOutlinedIcon sx={{ width: 20, height: 20 }} />
            )}
        </IconButton>
    )
}

export default PasswordVisibility