import { Box, Button, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';

function SubmitResetButtonGroup(props) {

    const { onSubmit, onReset, labelSubmit, labelReset, loading } = props

    return (
        <Box
            position='absolute'
            bottom='0'
            left='0'
            right='0'
        >
            <Divider />
            <Box
                display='flex' justifyContent='center'
                gap='20px'
                my='20px'
            >
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                    loading={loading}
                    type='submit'
                    sx={{ paddingX: '70px' }}
                >
                    <span>{labelSubmit}</span>    {/* span tags due to muiLab error */}
                </LoadingButton>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={onReset}
                    type='reset'
                >
                    {labelReset}
                </Button>
            </Box>
        </Box>
    )
}

export default SubmitResetButtonGroup