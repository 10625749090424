import React from 'react'
import { Box, CircularProgress } from '@mui/material';


function CircularProgressBasic() {

    return (
        <Box 
        width='100%' height='100%' 
        display='flex' justifyContent='center' alignItems='center'
        p='30px'
        >
            <CircularProgress color='secondary' />
        </Box>
      );
    }

export default CircularProgressBasic