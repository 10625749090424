import { createContext, useEffect, useReducer, useRef } from "react";
import { useDispatch } from 'react-redux'
import { setLoggedUser, logoutUser } from "../store/slices/loginSlice";
import { validateToken } from '../utils/jwt';
import { setSession, resetSession } from '../utils/session';
import axiosInstance from "../api/axios";


const initialState = {
    isAuthenticated: undefined,
    isInitialized: false,
    user: null
}

export const AuthContext = createContext({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
});

const handlers = {
    INITIALIZE: (state, action) => {
        const { isAuthenticated, user } = action.payload
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        }
    },
    LOGIN: (state, action) => {
        const { user } = action.payload
        return {
            ...state,
            isAuthenticated: true,
            user,
        }
    },
    LOGOUT: (state) => {
        return {
            ...state,
            isAuthenticated: false,
            user: null,
        };
    },
};

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;


export const AuthProvider = (props) => {

    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialState);
    const reduxDispatch = useDispatch();

    // preventing from initializing twice - react issue
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) return;
        const initialize = async () => {
            try {
                const accessToken = localStorage.getItem("accessToken")
                if (accessToken && validateToken(accessToken)) {
                    setSession(accessToken)

                    const response = await axiosInstance.get("/users/me");
                    const { data: user } = response

                    reduxDispatch(setLoggedUser(user));

                    dispatch({
                        type: "INITIALIZE",
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    });
                } else {
                    reduxDispatch(logoutUser())

                    dispatch({
                        type: "INITIALIZE",
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                }
            } catch (error) {
                reduxDispatch(logoutUser())

                dispatch({
                    type: "INITIALIZE",
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            };
        };
        initialize();
        isMounted.current = true;
    }, [reduxDispatch]);

    const getTokens = async (email, password) => {
        const formData = new FormData();    // OAuth2PasswordBearer in backend requires FormData
        formData.append("username", email);
        formData.append("password", password);
        try {
            const response = await axiosInstance.post("/auth/login", formData);
            setSession(response.data.access_token, response.data.refresh_token);
        } catch (error) {
            throw error;
        };
    };

    const login = async (email, password) => {
        try {
            await getTokens(email, password);
            const response = await axiosInstance.get("/users/me");
            const { data: user } = response;

            reduxDispatch(setLoggedUser(user));

            dispatch({
                type: "LOGIN",
                payload: {
                    user,
                },
            });
        } catch (error) {
            return Promise.reject(error)
        };
    };

    const logout = () => {
        resetSession();

        reduxDispatch(logoutUser())

        dispatch({
            type: "LOGOUT",
        });
    };

    return (
        <AuthContext.Provider value={{
            ...state,
            login,
            logout
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export const AuthConsumer = AuthContext.Consumer