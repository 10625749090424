import Draggable from 'react-draggable';
import { Paper } from '@mui/material';


function DragablePaperComponent(props) {

    return (
        <Draggable
            handle="#draggable-dialog-bar"
            //cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props}/>
        </Draggable>
    );
}

export default DragablePaperComponent