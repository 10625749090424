import { Button, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import AddIcon from '@mui/icons-material/Add';


function AddToListButton(props) {

    const { text, onClick, permission } = props

    const theme = useTheme()
    const colors = tokens(theme.palette.mode)


    return (
        <Button
            sx={{ backgroundColor: colors.assigned.button1 }}
            size='large'
            variant="contained"
            onClick={onClick}
            startIcon={<AddIcon />}
            disabled={!permission}
        >
            {text}
        </Button>
    )
}

export default AddToListButton