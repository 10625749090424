import { Box, IconButton } from '@mui/material';
import settings from '../../config.json'
import useMediaQuery from '../../hooks/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';


function TabsDialogContainer(props) {

    const { children, onClose } = props

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY)


    return (
        <Box
            width='100%'
            display='flex'
            flexDirection='column'
        >
            <Box
                id="draggable-dialog-bar"
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{ cursor: 'move' }}
                height='16px'
            >
                <DragHandleIcon
                    sx={{ color: 'divider', m: '0px' }}
                />
            </Box>
            <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    '& .MuiButtonBase-root': {
                        minHeight: '30px',
                    },
                }}
            >
                {children}
                <IconButton
                    onClick={onClose}
                    sx={{
                        mr: isAboveMobileSize ? '0px' : '10px',
                        ml: isAboveMobileSize ? '10px' : '10px',
                    }}
                >
                    <CloseIcon
                        sx={{
                            fontSize: '30px',
                        }}
                    />
                </IconButton>
            </Box>
        </Box>
    )
}

export default TabsDialogContainer