import { Divider } from "@mui/material"

function DividerLeftLabel(props) {

    const { label } = props

    return (
        <Divider 
            textAlign="left" 
            sx={{
                fontSize: '14px', 
                color: 'divider'}}
        >
            {label}
        </Divider>
    )
}

export default DividerLeftLabel