import { Box, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { useTranslation } from 'react-i18next';


export default function NoPermissionsOverlay() {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { t } = useTranslation();


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& .st1': {
          fill: theme.palette.mode === 'light' ? '#D2F0EA' : colors.greenAccent[500],
        },
        '& .st2': {
          fill: theme.palette.mode === 'light' ? '#FBD872' : '#FBD872',
        },
        '& .st3': {
          fill: theme.palette.mode === 'light' ? '#F6AF62' : '#F6AF62',
        },
      }}
    >
      <svg width="200px" height="200px" viewBox="0 0 64 64" id="Layer_1">
        <g>
          <g>
            <path className="st1" d="M46.44,36.38H17.56V22.44C17.56,14.48,24.04,8,32,8s14.44,6.48,14.44,14.44V36.38z M23.32,28.7h17.37v-6.26    c0-4.79-3.9-8.68-8.68-8.68c-4.79,0-8.68,3.9-8.68,8.68V28.7z" />
          </g>
          <path className="st2" d="M32,56L32,56c-9.8,0-17.74-7.94-17.74-17.74V27.74h35.48v10.52C49.74,48.06,41.8,56,32,56z" />
          <path className="st3" d="M36,37.73c0-2.21-1.79-4-4-4c-2.21,0-4,1.79-4,4c0,1.39,0.71,2.62,1.79,3.33v4.74c0,1.22,0.99,2.21,2.21,2.21   c1.22,0,2.21-0.99,2.21-2.21v-4.74C35.29,40.35,36,39.12,36,37.73z" />
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>
        {t('text.no_permissions')}
      </Box>
    </Box>
  );
};