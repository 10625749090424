export const formatNoResponseError = (error) => {
  return {
    response: {
      status: error.code,
      data: {
        detail: error.message,
      },
    },
  };
};
